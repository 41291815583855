<template>
      <div class="banner-container">
        <div  class="global_container_center banner-block" @click="changeBanner(0)" :class="`${meetingInfo.tab == 0 ? 'global_primary_text_color' : ''} `" >
          <div class="banner-text  global_container_center" :class="`${meetingInfo.tab ==0 ? 'banner-active': ''} `" >{{ banner[0].text }}</div>
        </div>
        <div class="banner-block-separator global_container_center">
          <div></div>
        </div>
        <div  class="global_container_center banner-block" @click="changeBanner(1)" :class="`${meetingInfo.tab == 1 ? 'global_primary_text_color' : ''} `" >
          <div class="banner-text  global_container_center" :class="`${meetingInfo.tab == 1 ? 'banner-active': ''} `" >{{ banner[1].text }}</div>
        </div>
      </div>
</template>
<script>
export default {
  name:"banner",
  props:{
    meetingInfo:{
      type:Object,
      default:()=>{
        return {
          message:[]
        }
      }
    }
  },
  data(){
    return {
      banner: [
        {
          text: "提问区",
          key: "chatList",
        },
        {
          text: "会议介绍",
          key: "info",
        }
      ]
    }
  },
  methods: {
    changeBanner(index){
      this.$emit("changeBanner",index)
    },
  },
}
</script>
<style scoped>
.banner-container{
  flex: 8.6vw 0 0;
  display: flex;
  align-items: center;
  font-size: 4.13vw;
  color: #555;
  font-weight: 700;
  width: 100%;
}

.banner-block {
  flex: 1 0 0;
  height:100%;
  border-top:1px solid #ebebeb;
  border-bottom:1px solid #ebebeb;
  position: relative;
}
.banner-text{
  height:100%;
  width:50%;
  position: relative;
}
.banner-active::after{
  content: "";
  display: inline-block;
  width:100%;
  height:0.5vw;
  position: absolute;
  bottom:calc(-0.37vw);
  right:0;
  z-index:9999;
  background:#008f3d;
  border-radius:0.5vw;
}
.banner-block-separator{
  flex:3vw 0 0;
  height:100%;
  border-top:1px solid #ebebeb;
  border-bottom:1px solid #ebebeb;
}

.banner-block-separator div{
  width:1px;
  height:4vw;
  background:#ccc
}
</style>